import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as yup from 'yup'
import { useUpdateEffect } from 'react-use'
import { isEmpty } from 'lodash'

import { IconButton, RIGHT_PANEL_SCROLL_CONTAINER_ID, SettableFieldType } from '@cutover/react-ui'
import { TasksDeleteModal } from 'main/components/runbook/modals/task-modals/tasks-delete-modal'
import { FormEditPanel, hasDynamicTemplate } from 'main/components/shared/form'
import { TaskShowPermissions, useTaskNotifications } from 'main/recoil/data-access'
import { useLanguage } from 'main/services/hooks'
import { FieldValue, TaskBase, TaskListTask, TaskShowTask } from 'main/services/queries/types'
import { setTask, TaskEditRestrcitedTaskPayload, TaskEditTaskPayload } from 'main/services/queries/use-task'
import { RunbookTaskUpdateResponse } from 'main/services/api/data-providers/runbook-types'
import { customFieldValidation, useCustomFieldFormCallback } from 'main/components/shared/custom-field-form'
import { buildDefaultFieldValues } from 'main/components/shared/custom-field-form/custom-field-form-helper'
import { FormType } from 'main/components/shared/form/form'
import { ApiError } from 'main/services/api/http-gateway-adapter'
import {
  CancelledLinkedResourceButton,
  CompletedTaskButton,
  InProgressTaskButton,
  StartableTaskButton
} from './task-action-buttons'
import { useSetActiveRightPanelTypeState } from 'main/components/layout/right-panel'
import {
  ActiveRunbookModel,
  ActiveRunbookVersionModel,
  ActiveRunModel,
  CommentModel,
  CurrentUserModel,
  CustomFieldGroupModel,
  CustomFieldModel,
  CustomFieldUserModel,
  TaskModel,
  TaskTypeModel
} from 'main/data-access'
import { TaskEditDeleteCfData } from './task-edit-delete-cf-data-content'
import { TaskEditUnlinkContent } from './task-edit-unlink-content'
import { TaskEditFormFields } from './task-edit-form-fields'

const FIELDS_TO_ALWAYS_RESET = [
  'start_display',
  'end_display',
  'stage',
  'predecessor_ids',
  'possible_predecessors_ids',
  'successor_ids',
  'duration'
]

export type TaskEditFormType = yup.InferType<ReturnType<typeof buildTaskEditFormSchema>>

const buildTaskEditFormSchema = (
  fieldValueValidation: ReturnType<typeof customFieldValidation>,
  linkableIds: number[]
) => {
  return yup.object({
    author_name: yup.string().nullable(),
    auto_finish: yup.boolean().nullable(),
    disable_notify: yup.string().oneOf(['true', 'false']),
    description: yup.string().nullable(),
    duration: yup.number().nullable(),
    end_actual: yup.number().nullable(),
    end_display: yup.number().nullable(),
    end_fixed: yup.mixed().nullable(), // is received as a number but it's saved as a Date object
    end_planned: yup.number().nullable(),
    end_requirements: yup.string().oneOf(['any_can_end', 'all_must_end', 'same_must_end']),
    field_values: fieldValueValidation,
    level: yup.string().oneOf(['level_1', 'level_2', 'level_3']),
    linked_resource_id: yup.number().when('task_type_id', {
      is: (taskTypeId: number) => linkableIds.indexOf(taskTypeId) !== -1,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable()
    }),
    linked_resource_name: yup.string().when('task_type_id', {
      is: (taskTypeId: number) => linkableIds.indexOf(taskTypeId) !== -1,
      then: schema => schema.required(),
      otherwise: schema => schema.nullable()
    }),
    message: yup.string().nullable(),
    name: yup.string().required(),
    name_template: yup.string().nullable(),
    possible_predecessors_ids: yup.array().of(yup.number().required()).notRequired(),
    predecessor_ids: yup.array().of(yup.number().required()).notRequired(),
    recipients: yup
      .object()
      .shape({
        users: yup.array().of(yup.mixed()).notRequired(),
        runbook_teams: yup.array().of(yup.number().required()).notRequired()
      })
      .notRequired(),
    recipient_runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    recipient_user_ids: yup.array().of(yup.number().required()).notRequired(),
    runbook_team_ids: yup.array().of(yup.number().required()).notRequired(),
    stage: yup.string().oneOf(['default', 'startable', 'in-progress', 'complete']),
    start_actual: yup.number().nullable(),
    start_display: yup.number().nullable(),
    start_fixed: yup.mixed().nullable(), // is received as a number but it's saved as a Date object
    start_latest_planned: yup.number().nullable(),
    start_requirements: yup.string().oneOf(['any_can_start', 'all_must_start']),
    stream_id: yup.number().required(),
    successor_ids: yup.array().of(yup.number().required()).notRequired(),
    task_type_id: yup.number().required(),
    linked_snippets: yup.array().of(yup.object()).notRequired(),
    user_ids: yup.array().of(yup.number().required()).notRequired(),
    functional_oauth_user: yup.boolean().nullable()
  })
}

type TaskEditPayload = TaskEditTaskPayload | TaskEditRestrcitedTaskPayload

type TaskEditFormProps = {
  taskListTask: TaskListTask
  taskEditShowState?: { task: TaskShowTask; last_user_id?: number; permissions?: TaskShowPermissions }
  onClose: () => void
}

export const TaskEditForm = ({ taskListTask, taskEditShowState, onClose }: TaskEditFormProps) => {
  const { t } = useLanguage('tasks', { keyPrefix: 'editPanel' })

  const {
    id: taskId,
    internal_id: taskInternalId,
    stage,
    comments_count: commentsCount,
    task_type_id: taskTypeId
  } = taskListTask
  const [defaultValues, setDefaultValues] = useState<TaskEditFormType | undefined>(undefined)
  const can = taskEditShowState?.permissions

  const taskEditFormRef = useRef<FormType<TaskEditFormType>>(null)
  const descriptionFieldRef = useRef<SettableFieldType>(null)
  const taskComments = CommentModel.useGetAllBy({ taskInternalId })
  const commentsCountState = taskComments.length
  const run = ActiveRunModel.useGet()
  const { id: runbookId, is_template: isTemplate } = ActiveRunbookModel.useGet()
  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const { openRightPanel: openCommentsPanel } = useSetActiveRightPanelTypeState('runbook-comments')
  const [showDeleteTaskModal, setShowDeleteTaskModal] = useState(false)
  const [customFieldConstraints, setCustomFieldConstraints] = useState<Record<number, number[]>>({})
  const [unlinkingResource, setUnlinkingResource] = useState(false)
  const [taskTypeIdState, setTaskTypeIdState] = useState<number>(taskTypeId)
  const processTaskUpdateResponse = TaskModel.useOnAction('update')
  const skipTask = TaskModel.useAction('skip')
  const taskLookup = TaskModel.useGetLookup()
  const { notifyTaskAction } = useTaskNotifications()
  const customFieldsLookup = CustomFieldModel.useGetLookup()
  const customFieldGroups = CustomFieldGroupModel.useGetAll()
  const customFieldsGroupsLookup = CustomFieldGroupModel.useGetLookup()
  const customFieldUsers = CustomFieldUserModel.useGetAll()
  const currentUserId = CurrentUserModel.useId()
  const { dynamic: isDynamic } = ActiveRunbookModel.useRunbookType()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const currentTaskType = taskTypeLookup[taskTypeIdState]
  const linkableIds = Object.entries(taskTypeLookup)
    .filter(([, rbType]) => rbType.linkable && rbType.key !== 'snippet')
    .map(([id]) => parseInt(id))

  const { getFieldValues, fieldValueValidation, buildFieldValuesAttributesRequestData, getCustomFieldData } =
    useCustomFieldFormCallback({
      applyToSlugs: ['task_edit'],
      customFieldsLookup,
      customFieldGroups
    })

  const { customFields, groupedCustomFields, integrationGroupedCustomFields } = getCustomFieldData(
    currentTaskType?.integration_action_items?.[0],
    { task_type_id: taskTypeIdState }
  )

  /**
   * Synchronizes the form state on `taskEditShowState` or certain taskListTask changes, whether due to updates made by the current user or incoming changes from a WebSocket event.
   *
   * - Resets the form fields to their calculated default values if the form is not dirty or if `taskEditShowState` updates are made by the same user.
   * - If the form is dirty, only resets fields that are either unmodified or included in the predefined list of fields to always reset.
   */
  useEffect(() => {
    if (!taskEditFormRef.current) return

    const values = buildDefaultValues()
    const { isDirty, dirtyFields } = taskEditFormRef.current.formState

    setDefaultValues(values)

    if (
      !defaultValues ||
      !isDirty ||
      (taskEditShowState?.task.id === taskListTask.id && taskEditShowState?.last_user_id === currentUserId)
    ) {
      taskEditFormRef.current.reset(values)
    } else {
      const schemaFieldsKeys = Object.keys(taskEditFormRef.current.schema?.fields || {})
      const dirtyFieldsKeys = Object.keys(dirtyFields || {})
      const dirtyFieldValuesIds = new Set(Object.keys(dirtyFields.field_values || {}))

      // conditionally reset form fields
      schemaFieldsKeys.forEach(field => {
        if ((!dirtyFieldsKeys.includes(field) || FIELDS_TO_ALWAYS_RESET.includes(field)) && field !== 'field_values') {
          taskEditFormRef.current?.resetField(field as keyof TaskEditFormType, {
            defaultValue: values[field as keyof typeof buildTaskEditFormSchema]
          })
        }
      })

      // conditionally reset field values
      Object.entries(values.field_values).forEach(([id, defaultValue]) => {
        if (!dirtyFieldValuesIds.has(id)) {
          taskEditFormRef.current?.resetField(`field_values[${id}]` as keyof TaskEditFormType, { defaultValue })
        }
      })
    }
  }, [taskEditShowState, taskListTask])

  /**
   * Builds the calculated `defaultValues` object for the form.
   *
   * Includes all task properties, not just those defined in the form schema, as they are required by the `task-edit-form-fields` component.
   */
  const buildDefaultValues = useCallback(() => {
    const stateTask = taskEditShowState?.task
    const task = { ...stateTask, ...taskListTask }

    const fieldValues = getFieldValues(task.field_values)
    const currentNameTemplate = taskEditFormRef.current?.getValues('name_template')
    const possiblePredecessorsIds = taskEditFormRef.current?.getValues('possible_predecessors_ids')

    return {
      ...task,
      auto_finish: !!task.auto_finish,
      disable_notify: task.disable_notify.toString(),
      name_template: task.name_template
        ? task.name_template
        : hasDynamicTemplate(currentNameTemplate ?? '')
        ? currentNameTemplate
        : undefined,
      start_fixed: task.start_fixed ? new Date(task.start_fixed * 1000) : null,
      end_fixed: task.end_fixed ? new Date(task.end_fixed * 1000) : null,
      end_display: task.end_display * 1000,
      start_display: task.start_display * 1000,
      possible_predecessors_ids: task.possible_predecessors_ids ?? possiblePredecessorsIds,
      functional_oauth_user: task.functional_oauth_user,
      runbook_component_field_values: (task as unknown as TaskShowTask).runbook_component_field_values,
      field_values: {
        ...buildDefaultFieldValues(customFields, groupedCustomFields, fieldValues, integrationGroupedCustomFields),
        ...fieldValues
      },
      linked_resource_id: task.linked_resource?.id,
      linked_resource_name: task.linked_resource?.meta?.name,
      linked_resource: task.linked_resource
    }
  }, [taskEditShowState, taskListTask])

  const handleSuccess = useCallback(
    (response: RunbookTaskUpdateResponse) => {
      notifyTaskAction(response)

      const currentFormValues = taskEditFormRef.current?.getValues()
      const fieldValues: { [x: number]: FieldValue } = {}
      response.task.field_values.forEach(fv => (fieldValues[fv.custom_field_id] = fv))
      taskEditFormRef.current?.reset({
        ...currentFormValues,
        field_values: fieldValues,
        recipient_runbook_team_ids: response.task.recipient_runbook_team_ids,
        recipient_user_ids: response.task.recipient_user_ids,
        recipients: undefined,
        linked_resource_id: response.task.linked_resource?.id
      })
      processTaskUpdateResponse(response)
    },
    [processTaskUpdateResponse, notifyTaskAction]
  )

  const handleError = (e: any) => {
    if (e instanceof ApiError) {
      document.getElementById(RIGHT_PANEL_SCROLL_CONTAINER_ID)?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleClickComments = () => {
    openCommentsPanel({ taskId, taskInternalId })
  }

  useUpdateEffect(() => {
    const fieldValues = taskEditFormRef.current?.getValues('field_values') || {}
    const updatedDefaultFieldValues = buildDefaultFieldValues(
      customFields,
      groupedCustomFields,
      fieldValues,
      integrationGroupedCustomFields
    )

    setCustomFieldConstraints(calculateCfConstraints)

    setUnlinkingResource(calcualateLinkedResource)

    taskEditFormRef.current?.setValue('field_values', updatedDefaultFieldValues)
  }, [taskTypeIdState])

  const calcualateLinkedResource = useMemo(() => {
    const originalTypeLinkable = taskTypeLookup[taskListTask.task_type_id]?.linkable
    const newTypeLinkable = taskTypeLookup[taskTypeIdState]?.linkable

    return originalTypeLinkable && !newTypeLinkable
  }, [taskTypeLookup, taskTypeIdState])

  const calculateCfConstraints = useMemo(() => {
    const taskSnapshot = taskLookup[taskId]
    const customFieldIds = taskSnapshot.field_values.map(fv => fv.custom_field_id)

    let cfConstraints: Record<number, number[]> = {}
    for (const id of customFieldIds) {
      if (
        customFieldsLookup[id].constraint &&
        !customFieldsLookup[id].constraint?.task_type_id?.includes(taskEditFormRef.current?.getValues('task_type_id'))
      ) {
        if (!cfConstraints[taskId]) {
          cfConstraints[taskId] = []
        }
        cfConstraints[taskId].push(id)
      }
    }

    return cfConstraints
  }, [taskTypeIdState])

  const handleSubmit = useCallback(
    async (values: TaskEditPayload) => {
      return setTask({ runbookId, runbookVersionId, taskId, payload: values })
    },
    [runbookId, runbookVersionId, taskId]
  )

  const footerButton = useMemo(() => {
    switch (stage) {
      case 'startable':
        return <StartableTaskButton taskId={taskId} />
      case 'in-progress':
        return <InProgressTaskButton taskId={taskId} />
      case 'complete':
        if (taskListTask.linked_resource?.current_status?.linked_task === 'cancelled') {
          return <CancelledLinkedResourceButton />
        } else {
          return <CompletedTaskButton taskId={taskId} />
        }
      default:
        return null
    }
  }, [stage])

  const handleSkipTask = () => skipTask(taskId)

  const canEditRestrictedAttributesOnly =
    (!isDynamic && run?.mode === 'active') || (isDynamic && !['default', 'startable'].includes(stage))

  const dataTransformer = ({
    field_values,
    ...rest
  }: {
    field_values: Record<number, FieldValue>
  } & TaskEditFormType) => {
    if (canEditRestrictedAttributesOnly) {
      const data = rest
      if (isDynamic) {
        return {
          task: {
            name: data.name,
            description: data.description,
            message: data.message,
            disable_notify: data.disable_notify === 'true',
            duration: data.duration,
            end_requirements: data.end_requirements
          },
          linked_snippets: data.linked_snippets,
          recipients: data.recipients,
          runbook_teams: data.runbook_team_ids,
          users: data.user_ids
        } as TaskEditRestrcitedTaskPayload
      } else {
        return {
          task: {
            name: data.name,
            description: data.description,
            message: data.message,
            disable_notify: data.disable_notify === 'true'
          },
          recipients: data.recipients
        } as TaskEditRestrcitedTaskPayload
      }
    } else {
      const data = rest
      const base = {
        task: {
          id: taskId,
          auto_finish: data.auto_finish,
          description: data.description === '<p></p>' ? null : data.description,
          disable_notify: data.disable_notify === 'true',
          duration: data.duration,
          end_fixed: data.end_fixed,
          end_requirements: data.end_requirements,
          field_values_attributes: buildFieldValuesAttributesRequestData(field_values),
          level: data.level,
          message: data.message,
          name: data.name_template ?? data.name,
          start_fixed: data.start_fixed,
          start_requirements: data.start_requirements,
          stream_id: data.stream_id,
          task_type_id: data.task_type_id
        },
        linked_snippets: data.linked_snippets,
        recipients: data.recipients,
        predecessors: data.predecessor_ids,
        runbook_teams: data.runbook_team_ids,
        users: data.user_ids
      } as TaskEditTaskPayload

      if (data.linked_resource_id) {
        base.linked_resource = {
          id: data.linked_resource_id,
          type: 'Runbook'
        }
      }

      return base
    }
  }

  const readOnly = !can?.update || ['in-progress', 'complete'].includes(taskListTask.stage)

  return (
    <>
      <TasksDeleteModal taskIds={[taskId]} open={showDeleteTaskModal} onClose={() => setShowDeleteTaskModal(false)} />
      <FormEditPanel<TaskEditFormType, TaskEditPayload>
        key={taskId}
        ref={taskEditFormRef}
        onReset={() => descriptionFieldRef.current?.reset()}
        title={t('title', { internalId: taskInternalId })}
        defaultValues={defaultValues}
        loading={!defaultValues || !taskEditShowState}
        onSubmit={handleSubmit}
        onClose={onClose}
        schema={buildTaskEditFormSchema(fieldValueValidation, linkableIds)}
        readOnly={readOnly}
        onSuccess={handleSuccess}
        onError={handleError}
        transformer={dataTransformer}
        headerItems={[
          ...(can?.skip
            ? [<IconButton tipPlacement="top" label={t('iconSkipLabel')} icon="skip" onClick={handleSkipTask} />]
            : []),
          <IconButton
            icon="message"
            label="Comments"
            onClick={handleClickComments}
            disableTooltip
            {...(commentsCountState || commentsCount
              ? { badge: { label: commentsCountState || commentsCount, type: 'primary' } }
              : {})}
          />,
          ...(can?.destroy
            ? [
                <IconButton
                  tipPlacement="top"
                  label={t('iconDeleteLabel')}
                  icon="trash-o"
                  onClick={() => setShowDeleteTaskModal(true)}
                />
              ]
            : [])
        ]}
        hasConfirmModal={!!unlinkingResource || !isEmpty(customFieldConstraints)}
        confirmModalTitle={
          !!unlinkingResource ? t('fields.linkedResource.unlinkTitle') : t('customFieldDeleteData.title')
        }
        confirmModalDescription={
          !!unlinkingResource ? (
            <TaskEditUnlinkContent
              resourceType={isTemplate ? 'template' : 'runbook'}
              linkedTasks={[taskListTask]}
              displayType="linked_resource"
            />
          ) : (
            <TaskEditDeleteCfData constraints={customFieldConstraints} />
          )
        }
        confirmModalButtonLabel={
          !!unlinkingResource ? t('fields.linkedResource.unlinkButton') : t('customFieldDeleteData.buttonLabel')
        }
        onCloseConfirmModal={() => {
          setUnlinkingResource(calcualateLinkedResource)
          setCustomFieldConstraints(calculateCfConstraints)
        }}
        onContinueConfirmModal={() => {
          if (!isEmpty(customFieldConstraints) && !unlinkingResource) {
            setCustomFieldConstraints({})
          }
          setUnlinkingResource(false)
        }}
        canContinueConfirmModal={() =>
          (!unlinkingResource && !isEmpty(customFieldConstraints)) ||
          (!!unlinkingResource && isEmpty(customFieldConstraints))
        }
        footer={!!run ? footerButton : undefined}
      >
        {can && defaultValues && (
          <TaskEditFormFields
            formRef={taskEditFormRef}
            task={defaultValues as unknown as TaskBase}
            permissions={can}
            readOnly={readOnly}
            descriptionFieldRef={descriptionFieldRef}
            customFields={customFields}
            customFieldGroupsLookup={customFieldsGroupsLookup}
            groupedCustomFields={groupedCustomFields}
            customFieldUsers={customFieldUsers}
            integrationGroupedCustomFields={integrationGroupedCustomFields}
            setTaskTypeId={setTaskTypeIdState}
          />
        )}
      </FormEditPanel>
    </>
  )
}
