// @ts-nocheck
import { injectable } from 'inversify'
import { container } from 'app/Config/IOC'
import { unescape } from 'lodash'
import { observable, action, computed, observe, toJS } from 'mobx'
import { Types } from 'Gateways/Service/Types'
import tinycolor2 from 'tinycolor2'
import { ILanguageService } from 'Shared/Helpers/Language/ILanguageService'
import { AccountsRepository } from 'app/Repositories/Account/AccountsRepository'
import { CustomFieldsRepository } from 'app/Repositories/CustomField/CustomFieldsRepository'
import { ConfigsRepository } from 'Configs/ConfigsRepository'
import { ISortParams, defaultOptionColor } from 'Components/Organisms'
import { ICustomFieldsPM } from 'Shared/Entities/Types/CustomField/ICustomFieldsPM'
import { CustomFieldRepository } from 'app/Repositories/CustomField/CustomFieldRepository'
import { GenericAdminViewPresenter, ViewModelType } from 'Shared/Presenters/GenericAdminViewPresenter'
import {
  ICustomFieldPM,
  IndicateConflicts,
  IConstraint,
  IOptions
} from 'app/Settings/CustomFields/CustomFieldRepositoryTypes'
import { textFieldType } from 'app/Settings/CustomFields/CustomFieldFieldTypes'
import { taskEditApplyTo } from 'app/Settings/CustomFields/CustomFieldApplyTo'
import { AvailableFields, AvailableOptions, ContextType } from './CustomFieldForm/CustomFieldFormPresenter'
import { TFieldOptionTypes } from 'Shared/Components/Organisms/FieldOptions/TFieldOptionTypes'
import { Submitted } from 'Shared/Entities/Enums/Submitted'
import { IBaseResponse } from 'Gateways/Service/IBaseResponse'
import { TRemoteOptionType } from 'Shared/Components/Organisms/FieldOptions/TRemoteOptionType'
import { ILoadParams } from 'Shared/Entities/ILoadParams'
import { CustomFieldGroupsRepository } from 'app/Repositories/CustomFieldGroupsRepository'
import { IGenericFilter } from 'app/Filters/FilterEntities'
import { capitalize } from 'lodash'
import { IDataSourceDTO } from 'Shared/Entities/Types/IDataSourceDTO'

export interface ICustomFieldVM {
  id?: number
  name: string
  displayName: string
  groupId?: number
  global: boolean
  accountId: number
  accountName: string
  fieldType: string
  required: boolean
  applyTo: string
  constraint: IConstraint
  constraintLabels?: string[]
  integrationActionItemLabels?: string[]
  displayList: boolean
  dataView: boolean
  displaySearch: boolean
  displayTable: boolean
  defaultValue: string
  default: boolean
  archived: boolean
  allowFieldCreation: boolean
  indicateConflicts?: IndicateConflicts
  remoteURL?: string
  fieldOptions?: TFieldOptionTypes[]
  fieldRemoteKeys?: TRemoteOptionType[]
  used: boolean
  options: IOptions
  defaultIntegrationActionItemId?: number
  disabledIntegrationActionItemLabels?: string[]
  dataSourceId: number
  dataSourceMappings?: string[]
  dependentCustomFieldValueKeys: string[]
}

interface ICustomFieldGroup {
  id: number
  name: string
}

export enum ConfirmMode {
  Standard,
  Delete
}

@injectable()
export class CustomFieldsViewPresenter extends GenericAdminViewPresenter {
  constructor() {
    super()
    this.addRecordVM = this.getDefaultVM()
    if (!this.addRecordVM.dataSourceMappings) {
      this.addRecordVM.dataSourceMappings = []
    }
    this.sortParams = {
      accessor: 'name',
      dataType: 'string',
      sortDirection: 'asc'
    }
  }

  private accountsRepository: AccountsRepository = container.get(AccountsRepository)
  private language: ILanguageService = container.get(Types.ILanguageService)
  private configsRepository: ConfigsRepository = container.get(ConfigsRepository)
  private customFieldGroupsRepository: CustomFieldGroupsRepository = container.get(CustomFieldGroupsRepository)
  protected recordRepository: CustomFieldRepository = container.get(CustomFieldRepository)
  public listRepository: CustomFieldsRepository = container.get(CustomFieldsRepository)

  protected routeId: string = 'customFields'

  @observable
  public filterVM: IGenericFilter[] = []

  @observable
  public addRecordVM: ICustomFieldVM

  @observable
  public editRecordVM: ICustomFieldVM

  @observable
  public submittedState: Submitted = Submitted.Unsubmitted

  @observable
  public sortParams: ISortParams

  @action
  protected customClear = () => {
    this.filterVM = []
  }

  @action
  public updateAddViewModel = (field: number, value: string) => {
    this.updateViewModel(ViewModelType.Add, field, value)
  }

  @action
  public updateEditViewModel = (field: number, value: string) => {
    this.updateViewModel(ViewModelType.Edit, field, value)
  }

  @action
  public setSubmittedState = (submitted: Submitted) => {
    this.submittedState = submitted
  }

  private isGlobalCustomField = (accountId: number, taskTypeAccountId: number): boolean => {
    return accountId === null || taskTypeAccountId === null
  }

  private updateViewModel = (type: ViewModelType, field: number, value: string) => {
    const viewModel = type === ViewModelType.Add ? this.addRecordVM : this.editRecordVM

    switch (field) {
      case AvailableFields.Name:
        viewModel.name = value
        break

      case AvailableFields.DisplayName:
        viewModel.displayName = value
        break

      case AvailableFields.Group:
        viewModel.groupId = parseInt(value, 10)
        break

      case AvailableFields.FieldType:
        viewModel.fieldType = value
        break

      case AvailableFields.DataSource:
        viewModel.dataSourceId = parseInt(value, 10)
        break

      case AvailableFields.ApplyTo:
        viewModel.applyTo = value
        break

      case AvailableFields.RemoteURL:
        viewModel.remoteURL = value
        break

      case AvailableFields.IndicateConflicts:
        viewModel.indicateConflicts =
          value === this.language.get('customFields:fields:conflicts:allow')
            ? IndicateConflicts.Allow
            : IndicateConflicts.Warn
        break

      case AvailableFields.DefaultContent:
      case AvailableFields.DefaultTextAreaContent:
        viewModel.defaultValue = value
        break

      case AvailableFields.Visibility:
        // Indicates account, to avoid account being named 'global'
        if (value.includes(':')) {
          viewModel.global = false
          const colonIndex = value.indexOf(':')
          const valueParts = [value.substring(0, colonIndex), value.substring(colonIndex + 1)]

          Object.keys(this.accountsVM).forEach(accountId => {
            if (valueParts[1] === this.accountsVM[accountId]) {
              viewModel.accountId = parseInt(accountId, 10)
            }
          })
        } else {
          viewModel.global = true
        }
        break
    }
  }

  @action
  public updateAddViewModelOptions = (field: number, options: TFieldOptionTypes[] | TRemoteOptionType[]) => {
    this.updateViewModelOptions(ViewModelType.Add, field, options)
  }

  @action
  public updateEditViewModelOptions = (field: number, options: TFieldOptionTypes[] | TRemoteOptionType[]) => {
    this.updateViewModelOptions(ViewModelType.Edit, field, options)
  }

  private updateViewModelOptions = (
    type: ViewModelType,
    field: number,
    options: TFieldOptionTypes[] | TRemoteOptionType[]
  ) => {
    const viewModel = type === ViewModelType.Add ? this.addRecordVM : this.editRecordVM

    switch (field) {
      case AvailableOptions.RemoteKeys:
        const remoteOptions = options as TRemoteOptionType[]
        viewModel.fieldRemoteKeys = remoteOptions
        break

      case AvailableOptions.GenericOptions:
        const fieldOptions = options as TFieldOptionTypes[]
        viewModel.fieldOptions = fieldOptions.map(option => {
          // Exclude color if it's the default
          if (tinycolor2(option.color).toHex() === tinycolor2(defaultOptionColor).toHex()) {
            delete option.color
          }

          return option
        })
        break

      default:
        break
    }
  }

  @action
  public updateAddViewModelWithGenericCheckboxAction = (
    field: number,
    label: string,
    value: boolean,
    context: number
  ) => {
    this.updateViewModelWithGenericCheckboxAction(ViewModelType.Add, field, label, value, context)
  }

  @action
  public updateEditViewModelWithGenericCheckboxAction = (
    field: number,
    label: string,
    value: boolean,
    context: number
  ) => {
    this.updateViewModelWithGenericCheckboxAction(ViewModelType.Edit, field, label, value, context)
  }

  private updateViewModelWithGenericCheckboxAction = (
    type: ViewModelType,
    field: number,
    label: string,
    value: boolean,
    context: number
  ) => {
    const viewModel = type === ViewModelType.Add ? this.addRecordVM : this.editRecordVM

    switch (field) {
      case AvailableFields.Constraint:
        const constraintLabel = this.getConstraintForContext(context)
        const constraintId = this.getIdForContextValue(context, viewModel.accountId, label)
        if (value && !viewModel.constraint) {
          viewModel.constraint = { [constraintLabel]: [] }
        }

        if (viewModel.constraint && !viewModel.constraint.hasOwnProperty(constraintLabel)) {
          viewModel.constraint[constraintLabel] = []
        }

        if (value) {
          viewModel.constraint[constraintLabel].push(constraintId)
        } else {
          viewModel.constraint[constraintLabel].splice(viewModel.constraint[constraintLabel].indexOf(constraintId), 1)

          if (viewModel.constraint[constraintLabel].length === 0) {
            delete viewModel.constraint[constraintLabel]
          }
        }
        break

      case AvailableFields.AdditionalSettings:
        this.setViewModelAdditionalSetting(viewModel, label, value)
        break

      case AvailableFields.IntegrationActionItem:
        const integrationActionItemId = this.getIdForIntegrationActionItemLabel(label)
        const integrationActionItemConstraintLabel = 'integration_action_item_id'

        if (!viewModel.constraint) {
          viewModel.constraint = {}
        }

        if (value) {
          viewModel.constraint[integrationActionItemConstraintLabel]
            ? viewModel.constraint[integrationActionItemConstraintLabel].push(integrationActionItemId)
            : (viewModel.constraint[integrationActionItemConstraintLabel] = [integrationActionItemId])
        } else {
          viewModel.constraint[integrationActionItemConstraintLabel].splice(
            viewModel.constraint[integrationActionItemConstraintLabel].indexOf(integrationActionItemId),
            1
          )
        }
        break
      case AvailableFields.DataSourceMappings:
        if (value) {
          if (viewModel.dataSourceMappings?.indexOf(label) < 0) {
            viewModel.dataSourceMappings.push(label)
          }
        } else {
          if (viewModel.dataSourceMappings.indexOf(label) > -1) {
            const index = viewModel.dataSourceMappings.indexOf(label)
            viewModel.dataSourceMappings.splice(index, 1)
          }
        }
        break
      default:
        break
    }
  }

  private getConstraintForContext = (context: number): string => {
    let constraintLabel = ''

    switch (context) {
      case ContextType.Events:
        constraintLabel = 'event_type_id'
        break

      case ContextType.Runbook:
        constraintLabel = 'runbook_type_id'
        break

      case ContextType.Task:
        constraintLabel = 'task_type_id'
        break
    }

    return constraintLabel
  }

  private getIdForContextValue = (context: number, accountId: number, label: string): number => {
    let id

    switch (context) {
      case ContextType.Events:
        id = this.getIdForEventTypeLabel(label)
        break

      case ContextType.Runbook:
        id = this.getIdForRunbookTypeLabel(label)
        break

      case ContextType.Task:
        id = this.getIdForTaskTypeLabel(label, accountId)
        break
    }

    return id
  }

  private setViewModelAdditionalSetting = (viewModel: ICustomFieldVM, label: string, value: boolean) => {
    switch (label) {
      case this.language.get('customFields:fields:additionalSettings:labels:allowCreation'):
        viewModel.allowFieldCreation = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:listView'):
        viewModel.displayList = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:dataView'):
        viewModel.dataView = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:filters'):
        viewModel.displaySearch = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:tableView'):
        viewModel.displayTable = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:required'):
        viewModel.required = value
        break
      case this.language.get('customFields:fields:additionalSettings:labels:transient'):
        viewModel.options ? (viewModel.options.transient = value) : (viewModel.options = { transient: value })
        break
      case this.language.get('customFields:fields:additionalSettings:labels:readOnly'):
        viewModel.options ? (viewModel.options.readonly = value) : (viewModel.options = { readonly: value })
        break
      case this.language.get('customFields:fields:additionalSettings:labels:lockName'):
        viewModel.options ? (viewModel.options.lock_name = value) : (viewModel.options = { lock_name: value })
        break
      case this.language.get('customFields:fields:additionalSettings:labels:hidden'):
        viewModel.options ? (viewModel.options.hidden = value) : (viewModel.options = { hidden: value })
        break
    }
  }

  protected getDefaultVM = () => {
    return {
      name: '',
      displayName: '',
      global: true,
      accountId: null,
      accountName: null,
      fieldType: textFieldType,
      required: false,
      applyTo: taskEditApplyTo,
      constraint: null,
      displayList: false,
      displaySearch: false,
      dataView: false,
      displayTable: false,
      defaultValue: '',
      default: false,
      archived: false,
      allowFieldCreation: false,
      remoteURL: null,
      fieldOptions: null,
      fieldRemoteKeys: null,
      used: false,
      options: {}
    }
  }

  @observable
  public reloadList = false

  public loadDependencies = async () => {
    await this.loadAccounts()
    await this.loadCustomFieldGroups()
    this.registerFilters()
    this.setFilterAndQueryObservers()
    await this.loadList()
  }

  protected setObservers = () => {}

  private setFilterAndQueryObservers = () => {
    const configObserver = observe(
      this.configsRepository,
      'customFieldTypes',
      () => {
        if (!!this.configsRepository.customFieldTypes) {
          this.registerFilters()
        }
      },
      true
    )

    const queryObserver = observe(this.routingState.currentState, 'query', () => {
      if (this.routingState.currentState.routeId === this.routeId) {
        this.loadList()
      }
    })

    this.observers = [configObserver, queryObserver, this.getRecordLoadingObserver(), this.getRecordToEditObserver()]
  }

  protected getProgrammersModelForRecord = (recordVM: ICustomFieldVM): ICustomFieldPM => {
    const recordPM: ICustomFieldPM = {
      name: recordVM.name,
      global: recordVM.global,
      accountId: recordVM.accountId,
      fieldType: recordVM.fieldType,
      required: recordVM.required,
      applyTo: recordVM.applyTo,
      constraint: recordVM.constraint,
      displayList: recordVM.displayList,
      dataView: recordVM.dataView,
      displaySearch: recordVM.displaySearch,
      displayTable: recordVM.displayTable,
      defaultValue: recordVM.defaultValue,
      default: recordVM.default,
      archived: recordVM.archived,
      allowFieldCreation: recordVM.allowFieldCreation,
      remoteURL: recordVM.remoteURL,
      fieldOptions: recordVM.fieldOptions,
      fieldRemoteKeys: recordVM.fieldRemoteKeys,
      used: recordVM.used,
      options: recordVM.options,
      dataSourceId: recordVM.dataSourceId,
      dataSourceMappings: recordVM.dataSourceMappings
    }

    if (recordVM.id) {
      recordPM.id = recordVM.id
    }

    if (recordVM.value_key) {
      recordPM.value_key = recordVM.value_key
    }

    if (recordVM?.options?.lock_name) {
      recordPM.displayName = recordVM.displayName
    }

    if (recordVM.indicateConflicts !== undefined) {
      recordPM.indicateConflicts = recordVM.indicateConflicts
    }

    if (recordVM.groupId) {
      recordPM.groupId = recordVM.groupId
    }

    return recordPM
  }

  public loadAccounts = async () => {
    await this.accountsRepository.safeLoadPermittedAccounts({ resource: 'custom-field' })
  }

  public loadCustomFieldGroups = async () => {
    await this.customFieldGroupsRepository.loadAll()
  }

  public loadSort = async (sortParams: ISortParams) => {
    this.sortParams = sortParams
    await this.loadList()
  }

  public loadList = async (params?: ILoadParams) => {
    await this.listRepository.loadData(params || { offSet: 0, sortParams: this.sortParams })
  }

  public loadMoreCustomFields = async (offSet: number) => {
    if (this.listRepository.isBatchRequested(offSet)) return
    await this.loadList({ offSet, sortParams: this.sortParams })
  }

  @computed
  public get listVM(): ICustomFieldsPM[] {
    if (this.listRepository.customFields && this.listRepository.customFields.length > 0) {
      return this.listRepository.customFields.map(cfTypeDto => {
        return {
          id: cfTypeDto.id,
          accountName: cfTypeDto.account,
          name: unescape(cfTypeDto.displayName),
          default: cfTypeDto.default,
          type: cfTypeDto.type,
          displaysOn: cfTypeDto.displaysOn
        }
      })
    }

    return []
  }

  @computed
  public get taskTypes() {
    return this.listRepository.taskTypes
  }

  @computed
  public get runbookTypes() {
    return this.listRepository.runbookTypes
  }

  @computed
  public get eventTypes() {
    return this.listRepository.eventTypes
  }

  @computed
  public get integrationActionItems() {
    return this.listRepository.integrationActionItems
  }

  @computed
  public get dataSourcesVM(): IDataSourceDTO[] {
    return this.listRepository.dataSources
  }

  @computed
  public get dependentCustomFieldValueKeys(): string[] {
    return this.recordRepository.dependentCustomFieldValueKeys
  }

  @computed
  public get customFieldGroups(): ICustomFieldGroup[] {
    if (!this.customFieldGroupsRepository.customFieldGroups.length) return []

    return this.customFieldGroupsRepository.customFieldGroups.map(customFieldGroup => {
      return { id: customFieldGroup.id, name: customFieldGroup.name }
    })
  }

  @computed
  public get customFieldsLoading(): boolean {
    return this.listRepository.loading || this.loading
  }

  public recordForEditReadOnly = (): boolean => {
    return !this.canUpdate || this.editRecordVM.default || this.editRecordVM.archived
  }

  @computed
  public get accountsVM(): { [key: number]: string } {
    if (this.accountsRepository.permittedAccounts.length === 0) return {}
    let accountLkup = {}
    this.accountsRepository.permittedAccounts.forEach(account => {
      accountLkup[account.id] = account.name
    })
    return accountLkup
  }

  private registerFilters = () => {
    if (!this.accountsRepository.permittedAccountsLoaded || this.configsRepository.customFieldTypes.length === 0) {
      return
    }

    const filters: IGenericFilter[] = [
      ...this.getVisibilityFilters(),
      ...this.getFieldTypeFilters(),
      ...this.getContextFilters(),
      ...this.getOtherFilters(),
      ...this.getGroupFilters()
    ]

    // Filters outside of filter panel
    filters.push({
      key: { reference: 'query' },
      type: 'string'
    })

    filters.push({
      key: { reference: 'archived' },
      type: 'boolean'
    })

    this.pageVM.filterPanelLoading = false

    this.filterVM = filters
    this.filtersRepository.setPageFilters(this.filterVM)
  }

  @computed
  public get fieldTypesVM(): { [key: string]: string } {
    if (this.configsRepository.customFieldTypes === 0) return {}

    let fileTypesLkup = {}

    this.configsRepository.customFieldTypes.forEach(customFieldType => {
      if (customFieldType.slug !== 'task_picker') {
        fileTypesLkup[customFieldType.slug] = customFieldType.name
      }
    })

    return fileTypesLkup
  }

  @computed
  public get contextsVM(): { [key: string]: string } {
    if (this.configsRepository.customFieldApplications.length === 0) return {}
    let contextsLkup = {}

    this.configsRepository.customFieldApplications.forEach(application => {
      contextsLkup[application.slug] = application.name
    })

    return contextsLkup
  }

  private getVisibilityFilters = (): IGenericFilter[] => {
    if (this.accountsRepository.permittedAccounts.length === 0) return []

    const filters: IGenericFilter[] = []
    const visibilityLabel = this.language.get('customFields:filters:visibility:title')

    filters.push({
      key: {
        reference: 'global',
        resourceId: 'true'
      },
      type: 'boolean',
      label: this.language.get('customFields:filters:visibility:global'),
      group: visibilityLabel,
      urlTrueValueOverride: 'true',
      groupPills: true,
      onChangeSideEffect: this.globalOrAccountSwitch
    })

    this.accountsRepository.permittedAccounts.forEach(account => {
      filters.push({
        key: {
          reference: 'account_id',
          resourceId: account.id.toString()
        },
        type: 'resource',
        label: account.name,
        group: visibilityLabel,
        groupPills: true,
        onChangeSideEffect: this.globalOrAccountSwitch
      })
    })

    return filters
  }

  private getGroupFilters = (): IGenericFilter[] => {
    if (this.customFieldGroupsRepository.customFieldGroups.length === 0) return []

    const filters: IGenericFilter[] = []
    const groupLabel = this.language.get('customFields:filters:groups:title')

    this.customFieldGroupsRepository.customFieldGroups.forEach(group => {
      filters.push({
        key: {
          reference: 'custom_field_group_id',
          resourceId: group.id.toString()
        },
        type: 'resource',
        label: group.name,
        group: groupLabel,
        groupPills: true
      })
    })

    return filters
  }

  private globalOrAccountSwitch = (selectedFilter: IGenericFilter, value?: string) => {
    if (!value) return

    // Ensure any account filters are removed
    if (selectedFilter.key.reference === 'global') {
      const checkedFiltersToReset = []

      this.filtersRepository.selectedFilters.forEach((filter: IGenericFilter) => {
        if (filter.key.reference !== 'account_id') return
        checkedFiltersToReset.push(filter)
      })

      return this.toggleCheckedFilters(checkedFiltersToReset)
    }

    // Account selected so reset global if applicable
    const globalSelectedFilter = this.filtersRepository.selectedFilters.find(
      (filter: IGenericFilter) => filter.key.reference === 'global'
    )

    if (globalSelectedFilter) {
      this.toggleCheckedFilters([globalSelectedFilter])
    }
  }

  private toggleCheckedFilters = (filters: IGenericFilter[]) => {
    if (!filters.length) return

    filters.forEach((filter: IGenericFilter) => {
      this.filtersRepository.toggleFilterWithoutSideEffects(filter)
    })

    this.router.refreshFilteredRoute()
  }

  private getFieldTypeFilters = (): IGenericFilter[] => {
    const filters: IGenericFilter[] = []
    const fieldTypeLabel = this.language.get('customFields:filters:fieldType:title')

    Object.keys(this.fieldTypesVM).forEach(slug => {
      filters.push({
        key: {
          reference: 'field_type',
          resourceId: slug
        },
        type: 'resource',
        label: this.fieldTypesVM[slug],
        group: fieldTypeLabel,
        groupPills: true
      })
    })

    return filters
  }

  private getContextFilters = (): IGenericFilter[] => {
    const filters: IGenericFilter[] = []
    const contextLabel = this.language.get('customFields:filters:context:title')

    Object.keys(this.contextsVM).forEach(slug => {
      filters.push({
        key: {
          reference: 'apply_to',
          resourceId: slug
        },
        type: 'resource',
        label: this.contextsVM[slug],
        group: contextLabel,
        groupPills: true
      })
    })

    return filters
  }

  private getOtherFilters = (): IGenericFilter[] => {
    return [
      {
        key: { reference: 'required' },
        type: 'boolean',
        group: this.language.get('customFields:filters:other:title'),
        label: this.language.get('customFields:filters:other:required')
      },
      {
        key: { reference: 'display_table' },
        type: 'boolean',
        group: this.language.get('customFields:filters:other:title'),
        label: this.language.get('customFields:filters:other:displayTable')
      }
    ]
  }

  private getIdForEventTypeLabel = (eventLabel: string): number => {
    const foundEvent = this.eventTypes.filter(eventType => eventLabel === eventType.name)
    return foundEvent[0].id
  }

  private getIdForTaskTypeLabel = (taskLabel: string, taskAccountId: number): number => {
    const foundTask = this.taskTypes.filter(
      taskType =>
        taskLabel === taskType.name &&
        (taskAccountId === taskType.accountId || this.isGlobalCustomField(taskAccountId, taskType.accountId))
    )
    return foundTask[0].id
  }

  public integrationHookType = (displayType?: string): string | undefined => {
    const applyTo = displayType ? displayType : this.addRecordVM.applyTo ?? this.editRecordVM.applyTo
    if (!applyTo) {
      return
    }

    let resourceType = applyTo.split('_')[0]
    resourceType = resourceType.charAt(0).toUpperCase() + resourceType.slice(1)

    return `::${resourceType}::`
  }

  private getIdForIntegrationActionItemLabel = (integrationActionItemLabel: string): number => {
    return this.integrationActionItems.find(iai => iai.name === integrationActionItemLabel).id
  }

  private getIdForRunbookTypeLabel = (runbookTypeLabel: string): number => {
    const foundTask = this.runbookTypes.filter(runbookType => runbookTypeLabel === runbookType.name)
    return foundTask[0].id
  }

  private getLabelForRunbookTypeId = (runbookTypeId: number): string => {
    const foundTask = this.runbookTypes.filter(runbookType => runbookTypeId === runbookType.id)
    return foundTask[0].name
  }

  private getLabelForTaskTypeId = (taskTypeId: number): string => {
    const foundTask = this.taskTypes.filter(taskType => taskTypeId === taskType.id)

    if (!foundTask || !foundTask[0]) return

    return foundTask[0].name
  }

  private getLabelForEventTypeId = (eventTypeId: number): string => {
    const foundTask = this.eventTypes.filter(eventType => eventTypeId === eventType.id)
    return foundTask[0].name
  }

  private getLabelForIntegrationActionItemId = (integrationActionItemId: number): string => {
    const foundIAI = this.integrationActionItems.filter(iai => integrationActionItemId === iai.id)
    return foundIAI?.[0]?.name
  }

  private getLabelsForDisabledIntegrationActionItemId = (integrationActionItemId: number): string => {
    const foundIAI = this.integrationActionItems.find(iai => integrationActionItemId === iai.id)
    const label = foundIAI?.name

    return label ? [label] : []
  }

  @action
  public inflateEditRecordVM = () => {
    // Lose the reference between recordVM and recordForEdit
    const recordPM = JSON.parse(JSON.stringify(this.recordRepository.recordForEdit))
    if (!recordPM) return (this.editRecordVM = null)
    const recordVM: ICustomFieldVM = this.getDefaultVM()

    recordVM.id = recordPM.id
    recordVM.name = recordPM.name
    recordVM.displayName = recordPM.displayName
    recordVM.global = recordPM.global
    recordVM.accountId = recordPM.accountId

    if (!recordPM.global) {
      recordVM.accountName = this.accountsVM[recordPM.accountId]
    }

    recordVM.fieldType = recordPM.fieldType
    recordVM.required = recordPM.required
    recordVM.applyTo = recordPM.applyTo
    recordVM.constraint = recordPM.constraint
    recordVM.defaultIntegrationActionItemId = recordPM.defaultIntegrationActionItemId

    if (recordVM.constraint) {
      recordVM.constraintLabels = this.getLabelsForConstraint(recordVM.constraint)
      recordVM.integrationActionItemLabels = this.getLabelsForConstraint(
        recordVM.constraint,
        'integration_action_item_id'
      )
      if (recordPM.defaultIntegrationActionItemId) {
        recordVM.disabledIntegrationActionItemLabels = this.getLabelsForDisabledIntegrationActionItemId(
          recordVM.defaultIntegrationActionItemId
        )
      } else {
        recordVM.disabledIntegrationActionItemLabels = []
      }
    }

    recordVM.dataSourceId = recordPM.dataSourceId
    recordVM.dependentCustomFieldValueKeys = recordPM.dependentCustomFieldValueKeys
    recordVM.displayList = recordPM.displayList
    recordVM.dataView = recordPM.dataView
    recordVM.displaySearch = recordPM.displaySearch
    recordVM.displayTable = recordPM.displayTable
    recordVM.defaultValue = recordPM.defaultValue
    recordVM.default = recordPM.default
    recordVM.archived = recordPM.archived
    recordVM.allowFieldCreation = recordPM.allowFieldCreation
    recordVM.remoteURL = recordPM.remoteURL
    recordVM.indicateConflicts = recordPM.indicateConflicts
    recordVM.fieldOptions = JSON.parse(JSON.stringify(recordPM.fieldOptions))
    recordVM.fieldRemoteKeys = JSON.parse(JSON.stringify(recordPM.fieldRemoteKeys))
    recordVM.used = recordPM.used
    recordVM.options = recordPM.options
    recordVM.value_key = recordPM.value_key
    if (recordPM.groupId) {
      recordVM.groupId = recordPM.groupId
    }

    this.editRecordVM = recordVM
    if (!this.editRecordVM.dataSourceMappings) {
      this.editRecordVM.dataSourceMappings = []
      if (this.editRecordVM.dependentCustomFieldValueKeys) {
        this.editRecordVM.dataSourceMappings = recordPM.dependentCustomFieldValueKeys
      }
    }
  }

  private getLabelsForConstraint = (constraint: IConstraint, type: string | undefined = undefined): string[] => {
    const labels = []
    const constraintType =
      type || Object.keys(constraint).filter(constraintType => constraintType !== 'integration_action_item_id')[0]

    constraint[constraintType] &&
      constraint[constraintType].forEach(constraintValue => {
        switch (constraintType) {
          case 'runbook_type_id':
            labels.push(this.getLabelForRunbookTypeId(constraintValue))
            break

          case 'task_type_id':
            constraintValue && labels.push(this.getLabelForTaskTypeId(constraintValue))
            break

          case 'event_type_id':
            labels.push(this.getLabelForEventTypeId(constraintValue))
            break

          case 'integration_action_item_id':
            constraintValue && labels.push(this.getLabelForIntegrationActionItemId(constraintValue))
            break
        }
      })

    return labels
  }

  @computed
  public get archiveConfirmName(): string {
    if (!this.editRecordVM) return ''
    return this.editRecordVM.name
  }

  @computed
  public get confirmMode(): ConfirmMode {
    if (!this.editRecordVM || !this.recordRepository.recordForEdit) return ConfirmMode.Standard

    const constraintBefore = toJS(this.recordRepository.recordForEdit.constraint)
    const constraintAfter = toJS(this.editRecordVM.constraint)

    // Adding a new constraint will result in other field values being removed
    if (!constraintBefore && constraintAfter) {
      return ConfirmMode.Delete
      // All constraints removed so existing field values are fine
    } else if (!constraintAfter || Object.keys(constraintAfter).length === 0) {
      return ConfirmMode.Standard
    }

    const typeKey = Object.keys(constraintBefore)[0]
    let constraintRemoved = false

    // Check if any of the previous constraints have been removed
    constraintBefore[typeKey].forEach(initialConstraintId => {
      const contains = constraintAfter[typeKey]?.filter(constraintId => constraintId === initialConstraintId)

      // Constaint has been removed
      if (contains?.length === 0) {
        constraintRemoved = true
      }
    })

    return constraintRemoved ? ConfirmMode.Delete : ConfirmMode.Standard
  }

  public refreshCache = async (): Promise<IBaseResponse> => {
    return await this.recordRepository.refreshCache()
  }
}
