import { IconButton, Message, RightPanel } from '@cutover/react-ui'
import { CloseRightPanelAction, useRightPanelTypeState } from 'main/components/layout/right-panel'
import { ActiveRunbookModel } from 'main/data-access'
import { useLanguage } from 'main/services/hooks'

export const ChangeRequestsPanel = () => {
  // TODO: check if we need runbookId in the right panel or any other attributes
  const [{ runbookId }, { closeRightPanel }] = useRightPanelTypeState('runbook-change-requests')
  // TODO: how do we retrieve change requests?

  return <>{runbookId && <ChangeRequestsPanelInner onClose={closeRightPanel} />}</>
}

type ChangeRequestsPanelInnerProps = {
  onClose: CloseRightPanelAction
}

export const ChangeRequestsPanelInner = ({ onClose }: ChangeRequestsPanelInnerProps) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'changeRequestsPanel' })
  const runbook = ActiveRunbookModel.useGet()

  const handleLinkCRQ = () => {
    console.log('Open link CRQ modal')
  }

  return (
    <RightPanel
      title={t('title')}
      onClose={onClose}
      headerItems={[
        <IconButton primary label={t('linkButton')} icon="add" onClick={handleLinkCRQ} tipPlacement="top" />
      ]}
    >
      {runbook.change_requests_count === 0 && <Message type="error" icon="alert" message={t('linkeErrorMessage')} />}
    </RightPanel>
  )
}
