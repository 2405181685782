import { CamelToSnakeCaseNested } from '@cutover/utility-types'
import { CustomField, FieldValue } from './custom-fields'

export type IntegrationFinishedStatus = 'failed' | 'success' | 'cancelled'
export type IntegrationRunningStatus = 'connect' | 'running'
export type IntegrationStatus = IntegrationFinishedStatus | IntegrationRunningStatus | 'queued' | 'rehearsal'

export const INTEGRATION_FINISHED_STATUSES: IntegrationFinishedStatus[] = ['failed', 'success', 'cancelled']
export const INTEGRATION_RUNNING_STATUSES: IntegrationRunningStatus[] = ['connect', 'running']

export type IntegrationSettingsResponse = {
  meta: {
    permissions: { [key: string]: number[] }
  }
  integration_settings: IntegrationSetting[]
}

export type IntegrationSetting = {
  id: number
  archived: boolean
  enabled: boolean
  image_url?: string
  imageUrl?: string
  integration: string
  integration_title: string
  name: string
  usage: string
  integration_action_items: IntegrationActionItem[]
  settings: Record<string, IntegrationOrActionSetting>
}

export type IntegrationActionItem = {
  id: number
  key: string
  archived: boolean
  created_at: number
  entity: {
    id?: number
    account_id?: number
    global?: boolean
    auto_start?: boolean
    enable_start_fixed?: boolean
    visibility?: number
  }
  functional_user_id: number
  functional_user: boolean
  image_url: string
  integration_action: string
  integration_setting: IntegrationSetting
  name: string
  oauth_user: {
    name: string
    has_session: boolean
  }
  on: string[]
  option_overrides: { [x: string]: {} }
  settings: Record<string, IntegrationOrActionSetting | string | number | boolean | {}>
  title: string
  global: boolean
  account_id: number
}

export type IntegrationOrActionSetting = {
  default?: string
  required?: boolean
  validation?: string
  label?: string
  helper?: string
  sensitive?: boolean
  placeholder?: string
  field_type?: string
  field_options?: string[]
  dependency_operator?: string
  tooltip?: string
  group?: string
  min?: string
  dependency?: [
    {
      dependent_setting: string
      value: string | string[]
    }
  ]
}

export type IntegrationEventSnakeCase = CamelToSnakeCaseNested<IntegrationEvent>

export type IntegrationEvent = {
  errorReason: string | null
  eventId: string
  integrationActionItemId: number
  integrationOn: string
  newFieldOptionIds: number[] | string[] | null
  progress: string | null
  status: IntegrationStatus
  timestamp: string
  user: number | string | null
}

export type RunbookIntegration = {
  id: number | null
  checked: boolean
  linkableId: number
  linkableType: 'RunbookVersion'
  integrationActionItemId: number | null
  integrationSettingName: string
  inProgress?: boolean
  integrationOptions: { [x: string]: {} | boolean }
  integrationActionItem: RunbookIntegrationActionItem
  customFields: CustomField[]
  fieldValues: FieldValue[]
  integrationEvents: IntegrationEvent[]
  createdAt: string
  updatedAt: string
  order: number
}

export type RunbookIntegrationActionItem = {
  id: number
  key: string
  name: string
  integrationAction: string
  on: string[]
  imageUrl: string
  optionOverrides: { [x: string]: {} }
  settings: {
    location: string | undefined
  }
  archived: boolean
}
