import { ReactNode, Suspense } from 'react'

import { LayoutLoading } from 'main/components/layout/layout-loading'
import { RunbookPageRequests } from './runbook-page-requests'
import { RunbookChannelSubscriber } from './runbook-channel-subscriber'
import { RunbookChannelQueueProcessor } from './runbook-channel-queue-processor'

export const RunbookDataRequestsAndChannelSubscriber = ({ children }: { children: ReactNode }) => {
  // The order of these components is very important!! The subscription to the channel must be connected before
  // any requests to the runbook endpoints are made and the queue processing must only occur when the loading
  // has finished.
  // If the channel isn't connected then messages may be missed while waiting for requests to resolve. A stale version of those
  // objects will then be returned which will lead to a crash if any subsequent messages reference missed data. (eg, task id not
  // existing)
  // If the queue is processed before the requests are resolved, those messages will not be saved in recoil state. Again, will
  // lead to a crash for the same reason.
  return (
    <RunbookChannelSubscriber>
      <RunbookPageRequests>
        {({ isLoading, isError }) => (
          <RunbookChannelQueueProcessor isLoading={isLoading} isError={isError}>
            {isLoading ? (
              <LayoutLoading filterPanel subHeader rightNav />
            ) : (
              <Suspense fallback={<LayoutLoading filterPanel subHeader rightNav />}>{children}</Suspense>
            )}
          </RunbookChannelQueueProcessor>
        )}
      </RunbookPageRequests>
    </RunbookChannelSubscriber>
  )
}
