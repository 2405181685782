import { atom, selector } from 'recoil'

import {
  fetchAccountQuery,
  GetAccountResponseType
} from 'main/services/api/data-providers/account/use-get-account-data'

// WARNING: there is a circular dependency somewhere that seems to depend entirely on this fetchAccountQuery being
// imported here
fetchAccountQuery

export const accountResponseState_INTERNAL = atom<GetAccountResponseType>({
  key: 'account:response'
})

export const accountState = selector({
  key: 'account',
  get: ({ get }) => {
    const { account } = get(accountResponseState_INTERNAL)
    return account
  }
})

export const accountMetaState = selector({
  key: 'account:meta',
  get: ({ get }) => {
    const { meta } = get(accountResponseState_INTERNAL)
    return meta
  }
})
