import { Box } from '@cutover/react-ui'
import { SubHeaderButton, SubHeaderButtonGroup, SubHeaderSearch } from 'main/components/layout/shared/sub-header'
import { useClearFilterParam, useFilters, useSetFilterParams } from 'main/components/shared/filter/filter-provider'
import { useLanguage } from 'main/services/hooks'

const DATASOURCE_VISIBILITY_STATUS = ['has_templates', 'no_templates'] as const

export const WorkspaceDataSourcesSubHeader = () => {
  const { t } = useLanguage('dataSources')

  const { filters } = useFilters()
  const setFilter = useSetFilterParams()
  const clearFilter = useClearFilterParam()

  const handleSearch = (input?: string) => {
    if (input) {
      setFilter({ q: input })
    } else {
      clearFilter('q')
    }
  }

  const handleClickOption = (status?: (typeof DATASOURCE_VISIBILITY_STATUS)[number]) => {
    if (!status) {
      clearFilter('t_filter')
    }

    setFilter({ t_filter: status })
  }

  return (
    <Box
      direction="row"
      css={`
        padding-top: 14px;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 14px;
      `}
    >
      <Box direction="row">
        <SubHeaderSearch
          placeholder={t('dataSources.searchDataSources')}
          onSearch={handleSearch}
          initialValue={filters.q as string}
        />
        <SubHeaderButtonGroup>
          <SubHeaderButton label={t('filter.all')} active={!filters.t_filter} onClick={() => handleClickOption()} />
          <SubHeaderButton
            label={t('filter.hasTemplates')}
            active={filters.t_filter === 'has_templates'}
            onClick={() => handleClickOption('has_templates')}
          />
          <SubHeaderButton
            label={t('filter.noTemplates')}
            active={filters.t_filter === 'no_templates'}
            onClick={() => handleClickOption('no_templates')}
          />
        </SubHeaderButtonGroup>
      </Box>
    </Box>
  )
}
