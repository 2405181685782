import { useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { eventManager } from 'event-manager'

import {
  Box,
  ItemCreateInput,
  Message,
  Modal,
  resolveColor,
  TeamListItem,
  UserListItem,
  useTheme
} from '@cutover/react-ui'
import { SelectedUsersTeamsForm } from './selected-users-teams-form'
import { ErrorItem, FormValues, SelectedTeam, SelectedUser, SelectedUserTeam } from './types'
import { useAccountUsersTeams } from './use-account-users-teams'
import { DynamicSearchBar } from './dynamic-search-bar'
import { RunbookPeoplePayload, RunbookTeamPayload, useRunbookPeopleCreate } from './use-runbook-people-create'
import { Stream, ValidationRunbookTeam } from '../../right-panels/people-panel/types'
import { queryClient } from 'main/query-client'
import { useLanguage } from 'main/services/hooks'
import { useValidation } from './use-validation'
import { ConfigModel, RunbookUserModel } from 'main/data-access'

export type AddUsersTeamsModalProps = {
  isOpen: boolean
  accountId: number
  closeModal: () => void
  runbookId: number
  runbookVersionId: number
  runbookTeams: ValidationRunbookTeam[]
  setRunbookTeams: (array: ValidationRunbookTeam[]) => void
  streams: Stream[]
  templateType?: string
  bypassNotification?: boolean
}

export const AddUsersTeamsModal = ({
  isOpen,
  accountId,
  runbookId,
  runbookVersionId,
  closeModal,
  runbookTeams,
  setRunbookTeams,
  streams,
  templateType,
  bypassNotification = false
}: AddUsersTeamsModalProps) => {
  const reactRunbookEnabled = ConfigModel.useIsFeatureEnabled('react_runbook')
  const processRunbookPersonCreateResponse = RunbookUserModel.useOnAction('create')

  const { t } = useLanguage('runbook', { keyPrefix: 'addUsersTeams' })
  const [errorList, setErrorList] = useState<ErrorItem[]>([])
  const [newCustomTeamIndex, setNewCustomTeamIndex] = useState<number | null>(null)
  const { maxRunbookTeams, maxRunbookUsers } = ConfigModel.useGet()
  const { validateUserTeamCount, validateTeamName, validateUserName, displayWarning } = useValidation()
  const { items, onInputChange, inputValue, loadingState } = useAccountUsersTeams({
    accountId
  })

  const listRef = useRef<HTMLUListElement>(null)

  const methods = useForm<FormValues>({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {}
  })

  const { fields, insert, remove } = useFieldArray<FormValues, 'usersTeamsSelect', 'id'>({
    control: methods.control,
    name: 'usersTeamsSelect', // unique name for your Field Array
    keyName: 'key' as 'id'
  })

  // Watch usersTeamsSelect to get items with updated name or linked prop of team
  const usersTeams = methods.watch('usersTeamsSelect') || []

  // get usersCount and teamsCount cached data for frontend validation
  const runbookUsersCountCached = queryClient.getQueryData<number>(['usersCount', runbookVersionId])
  const runbookTeamsCountCached = queryClient.getQueryData<number>(['teamsCount', runbookVersionId])

  const validateItemName = (item: SelectedUserTeam) => {
    let warningMessage = null

    if (item.type === 'team') {
      warningMessage = validateTeamName({ item, selectedItems: usersTeams, runbookTeams })
    }

    if (item.type === 'user') {
      warningMessage = validateUserName({ item, selectedItems: usersTeams })
    }

    if (warningMessage) {
      const errorList: ErrorItem[] = [
        {
          message: warningMessage,
          itemId: item.id,
          itemName: item.name,
          itemType: item.type
        }
      ]
      setErrorList(errorList)
    }
  }

  const addItem = (item: SelectedUserTeam) => {
    // Initial validation - prevents item being added at all
    if (
      displayWarning(
        validateUserTeamCount({
          item,
          selectedItems: usersTeams,
          runbookTeamsCount: runbookTeamsCountCached,
          maxRunbookTeams,
          runbookUsersCount: runbookUsersCountCached,
          maxRunbookUsers
        })
      )
    ) {
      onInputChange('')
      return
    }

    const index = usersTeams ? usersTeams.findIndex(field => item.name < field.name) : 0
    const itemIndex = index === -1 ? fields.length : index
    // By default selected team is linked
    if (item.type === 'team' && item.highlight) {
      item.linked = true
    }
    // Insert item in aphabetical order
    insert(itemIndex, item as SelectedUserTeam)

    if (!item.id) {
      setNewCustomTeamIndex(itemIndex)
    }

    validateItemName(item)
    onInputChange('')
  }

  useEffect(() => {
    if (newCustomTeamIndex !== null && listRef?.current) {
      // Scroll into view newly added custom team
      const ulList = listRef.current as HTMLElement
      const newCustomTeamElement = ulList ? ulList.children[newCustomTeamIndex] : null
      newCustomTeamElement?.scrollIntoView()
    }
  }, [newCustomTeamIndex])

  const removeErrorItem = (id: number, type: 'user' | 'team') => {
    const updatedErrorList =
      errorList.filter((errorItem: ErrorItem) => !(errorItem.itemId === id && errorItem.itemType === type)) || null
    setErrorList(updatedErrorList)
  }

  const removeItem = (index: number) => {
    const item = fields[index]
    const { id, type } = item
    const errorItem = errorList?.find(error => error.itemId === id && error.itemType === type)

    if (errorItem) {
      removeErrorItem(id, type)
    }

    const hasBackendError = !!errorList?.find(error => error.source === 'backend')

    if (hasBackendError) {
      setErrorList([])
    }

    remove(index)
  }

  const mutation = useRunbookPeopleCreate(runbookId, runbookVersionId, {
    onSuccess: data => {
      if (reactRunbookEnabled) {
        processRunbookPersonCreateResponse(data)
      } else {
        eventManager.emit('runbook-teams-users-added')
      }
    }
  })

  const onSubmit = (data: any) => {
    if (data.usersTeamsSelect) {
      const payload: RunbookPeoplePayload = {
        users: data.usersTeamsSelect
          .filter((item: SelectedUser) => item.type === 'user')
          .map((user: SelectedUser) => {
            const { streamIds, ...restUser } = user
            return { ...restUser, stream_ids: streamIds }
          }),
        runbook_teams: data.usersTeamsSelect.reduce((runbookTeams: RunbookTeamPayload[], item: SelectedTeam) => {
          if (item.type === 'team') {
            const runbookTeam: RunbookTeamPayload = {
              name: item.name,
              linked: item.linked,
              type: item.type,
              user_ids: item.userIds
            }
            if (item.hasOwnProperty('id')) {
              runbookTeam.team_id = item.id
            }
            runbookTeams.push(runbookTeam)
          }
          return runbookTeams
        }, [])
      }

      mutation.mutate(payload)
    }
  }

  const theme = useTheme()
  const emptyColor = resolveColor('text-disabled', theme)

  const errorMessages = errorList.map(errorItem => errorItem.message)
  const hasCustomTeam = !!fields?.find(field => !field.id)
  const hasUser = !!fields?.find(field => field.type === 'user')
  const customTeam = {
    id: 0,
    name: '',
    type: 'team',
    linked: false,
    color: emptyColor
  } as SelectedTeam

  return (
    <Modal
      title={t('heading')}
      open={isOpen}
      onClose={closeModal}
      onClickConfirm={methods.handleSubmit(onSubmit)}
      loading={mutation.isLoading}
      loadingText={t('common:savingText')}
      confirmText={t('common:ariaLabels.add')}
      confirmTestId="user-team-add"
      confirmDisabled={mutation.isLoading || fields.length === 0 || errorList.length !== 0}
      // Note: commented out the new way to create custom team to avoid having to test & speed up getting the branch done
      customButton={
        <ItemCreateInput
          onCreateItem={item => {
            let newTeam = customTeam
            newTeam.name = item
            addItem(newTeam)
          }}
          addNewLabel={t('addCustomTeam')}
          placeholder={t('chooseName')}
        />
      }
    >
      <Box css={'position: sticky; z-index: 999; top: 0;'} background="modal-bg">
        {errorMessages.length > 0 && (
          <Box margin={{ bottom: '16px' }}>
            <Message type="error" message={errorMessages} />
          </Box>
        )}
        <DynamicSearchBar
          placeholder={t('searchFor')}
          width="100%"
          onSearch={onInputChange}
          search={inputValue}
          items={items as SelectedUserTeam[]}
          loading={loadingState === 'filtering'}
          setSelectedItem={addItem}
          disabled={errorList.length !== 0}
          customTeam={customTeam}
          minLength={2}
          renderItem={item =>
            item.type === 'team' ? (
              <TeamListItem
                id={item.id}
                size="small"
                usersCount={item.usersCount ?? 0}
                name={item.name}
                linked={item.linked}
                color={item.color}
                onClick={() => {}} // to trigger hover style, click handler is on parent in dynamic-search-bar
              />
            ) : (
              <UserListItem
                id={item.id}
                size="small"
                onClick={() => {}}
                online={item.online}
                name={item.name}
                color={item.color}
              />
            )
          }
        />
      </Box>
      {hasCustomTeam && hasUser && (
        <Box margin={{ top: '16px' }}>
          <Message type="idea" message={t('customTeamHint')} />
        </Box>
      )}
      <Box
        css={`
          flex: 1 1 auto;
          width: 100%;
          position: relative;
          min-height: 200px;
        `}
      >
        <SelectedUsersTeamsForm
          methods={methods}
          closeModal={closeModal}
          removeItem={removeItem}
          items={fields}
          runbookTeams={runbookTeams}
          setRunbookTeams={setRunbookTeams}
          setErrorList={setErrorList}
          errorList={errorList}
          removeErrorItem={removeErrorItem}
          streams={streams}
          mutation={mutation}
          templateType={templateType}
          ref={listRef}
          bypassNotification={bypassNotification}
        />
      </Box>
    </Modal>
  )
}
