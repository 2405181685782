import { useQuery } from 'react-query'

import { apiClient } from 'main/services/api'
import { QueryKeys } from './query-keys'
import { DataSourceValues } from './types'

type DataSourceValuesParamsType = {
  query: string
  cf_id: number
  account_id: number
}

export function useDataSourceValuesQuery(params: DataSourceValuesParamsType) {
  return useQuery<DataSourceValues, Error>([QueryKeys.DataSourceValues, params], async () => {
    const { data } = await apiClient.get<DataSourceValues>({
      url: 'data_source_values',
      params
    })

    return data
  })
}
