import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
/* eslint-disable-next-line no-restricted-imports */ // this is very intentional; please do not copy this!
import { useSetRecoilState } from 'recoil'

import { useGetRunbook } from 'main/services/queries/use-runbook'
import { useGetRunbookVersion } from 'main/services/queries/use-runbook-versions'
import { useGetTasks } from 'main/services/queries/use-tasks'
import {
  accountResponseState_INTERNAL,
  runbookResponseState_INTERNAL,
  runbookVersionResponseState_INTERNAL,
  taskListResponseState_INTERNAL
} from 'main/recoil/runbook'
import { taskListResponseDataHelpers } from 'main/data-access/hooks/recoil'
import { useGetAccountData } from 'main/services/api/data-providers/account/use-get-account-data'

// NOTE: cache time is set to 0 bc we always want most up to date info whenever we enter a runbook
const CACHE_TIME = 0

export const RunbookPageRequests = ({
  children
}: {
  children: (context: { isLoading: boolean; isError: boolean }) => ReactNode
}) => {
  const { runbookId, runbookVersionId, accountId } = useParams<{
    runbookId: string
    runbookVersionId: string
    accountId: string
  }>()

  const setRunbookRequestData = useSetRecoilState(runbookResponseState_INTERNAL)
  const setRunbookVersionRequestData = useSetRecoilState(runbookVersionResponseState_INTERNAL)
  const setTaskListRequestData = useSetRecoilState(taskListResponseState_INTERNAL)
  const setAccountRequestData = useSetRecoilState(accountResponseState_INTERNAL)

  // Account data should not be rerequested at this point and should be read from the cache as it's already been requested at a workspace level
  const { isLoading: accountDataIsLoading, isError: accountDataError } = useGetAccountData(
    { slug: accountId },
    { onSuccess: setAccountRequestData }
  )

  const { isLoading: runbookDataLoading, isError: runbookDataError } = useGetRunbook(
    { runbookId },
    {
      onSuccess: setRunbookRequestData,
      cacheTime: CACHE_TIME
    }
  )
  const {
    data: runbookVersionData,
    isLoading: runbookVersionDataLoading,
    isError: runbookVersionDataError
  } = useGetRunbookVersion(
    { runbookId, runbookVersionId },
    {
      onSuccess: setRunbookVersionRequestData,
      cacheTime: CACHE_TIME
    }
  )

  const { isLoading: taskListDataLoading, isError: taskListDataError } = useGetTasks(
    { runbookId, runbookVersionId },
    // NOTE: make task list request when runbook version request has finished bc we need the runbook version response
    // to populate initial lookups (eg, task id to runbook component field value lookup)
    {
      enabled:
        !!runbookVersionData &&
        !runbookVersionDataLoading &&
        !runbookVersionDataError &&
        !!runbookId &&
        !!runbookVersionId,
      onSuccess: response => {
        if (!runbookVersionData?.meta.runbook_components)
          throw new Error('No runbook version request data to create lookups')

        setTaskListRequestData(
          taskListResponseDataHelpers.extend(response, {
            runbookComponents: runbookVersionData.meta.runbook_components
          })
        )
      },
      cacheTime: CACHE_TIME
    }
  )

  const isLoading = runbookDataLoading || runbookVersionDataLoading || taskListDataLoading || accountDataIsLoading
  const isError = runbookVersionDataError || taskListDataError || runbookDataError || accountDataError

  return (
    <>
      {children({
        // NOTE: we don't return data here because it's set in recoil state. If you need access to it, please
        // use the relevant models (eg. TaskModel, ActiveRunbookModel)
        isLoading,
        isError
      })}
    </>
  )
}
