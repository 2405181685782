import { useState } from 'react'

import { RunbookActivityPage } from '../components/activities/runbook-activity-page'
import { ReactAngularConnector } from 'main/connectors/react-angular-connector'

export const ActivitiesPageConnector = () => {
  const [runbookId, setRunbookId] = useState<number | null>(null)
  const [currentUserId, setCurrentUserId] = useState<number | null>(null)
  const [timezone, setTimezone] = useState('')

  return (
    <ReactAngularConnector
      mountEvent="show-runbook-activities"
      unmountEvent="hide-runbook-activities"
      onMount={({ runbookId, currentUserId, timezone }) => {
        setRunbookId(runbookId)
        setCurrentUserId(currentUserId)
        setTimezone(timezone)
      }}
    >
      {runbookId && currentUserId && (
        <RunbookActivityPage runbookId={runbookId} currentUserId={currentUserId} timezone={timezone} />
      )}
    </ReactAngularConnector>
  )
}
